import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { getAssetUrl } from '../services/cdn'
import { Container as BlockContainer } from '../components/Blocks'
import { HeroCarousel } from '../components/Sections'
import Inquiry from '../components/Forms/Inquiry'
import { Container, Row, Col } from 'reactstrap'

const IndexPage = () => (
  <Layout>
    <SEO title="Inquire" keywords={[`gatsby`, `application`, `react`]} />
    <BlockContainer padding={4} className="mt-3">
      <Container fluid className="p-0">
        <Row>
          <Col md="12">
            <HeroCarousel
              items={[
                {
                  src: getAssetUrl('agriculture/KoopLikas-1.jpg'),
                  altText: 'KOOPLIKAS',
                  captionHeader: '',
                  caption: ''
                },
                {
                  src: getAssetUrl('agriculture/Contract-Growing-3.jpg'),
                  altText: 'CONTRACT GROWING',
                  captionHeader: '',
                  caption: ''
                },
                {
                  src: getAssetUrl('agriculture/Feedmill-Slider-2.jpg'),
                  altText: 'FEEDMILL',
                  captionHeader: '',
                  caption: ''
                },
                {
                  src: getAssetUrl('agriculture/Layer-Farm-4.jpg'),
                  altText: 'Layer Farm',
                  captionHeader: '',
                  caption: ''
                }
              ]}
            />
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={2}>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">Send us your concerns</h1>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <Inquiry />
  </Layout>
)

export default IndexPage
